import * as queries from '../../graphql/queries';
import {API} from "aws-amplify";
import React, {Component} from 'react';
import _ from 'lodash';
import {listSTUDENTS} from "../../graphql/queries";
import {
    View,
    Text,
    Flex,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody
} from "@aws-amplify/ui-react";

import EditItemModal from "../../components/EditAccess/editAccessId";
class IdDashboard extends Component{


    constructor(props){
        super(props)
        this.state = {itemData: {}, item: {}, modalOpen: false}
        this.getItems = this.getItems.bind(this);

    }
   async getItems(){
        try {
            await API.graphql({query: listSTUDENTS, authMode: "AMAZON_COGNITO_USER_POOLS"}).then(response => {
                console.log(response)
                this.setState({
                    itemData: response.data.listSTUDENTS.items
                });
            });
        } catch (error){
            console.log('error on fetching items', error);
        }

    }
   async getItem(id){
        console.log("Id Sent to getItem: ", id)
        try {
            await API.graphql({query: queries.getSTUDENTS, variables: {id: id}}).then(response => {
                console.log(response)
                this.setState({
                    item: response.data.getSTUDENTS
                })
            });
            return this.state.item
        } catch (error){
            console.log("Error with getItem ", error);
        }
    }
    componentDidMount(){
        this.getItems()
    }
    accessColor(rec){

        if (rec.value) {
            return <div id={rec.studentId+"-"+rec.itemRequest} className="circle-connected"/>;

        } else {
            return <div id={rec.studentId+"-"+rec.itemRequest} className="circle-disconnected"/>;
        }


    }
    render() {
        const itemData = this.state.itemData;
        return (

            <View>
                <Flex>
                <Table  highlightOnHover={true}
                        justifyContent="center"
                        alignItems="center">
                    <TableHead>
                        <TableRow >
                            <TableCell as="th">Name</TableCell>
                            <TableCell as="th">Access Card Id</TableCell>
                            <TableCell as="th">Building Access</TableCell>
                            <TableCell as="th">Off Campus Lunch</TableCell>
                            <TableCell as="th"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                {_.map(itemData,(idcard) => (
                    <TableRow key={idcard.id}>
                        <TableCell>
                        <Text fontWeight={700}>
                            {idcard.firstName} {idcard.lastName}
                        </Text>
                        </TableCell>
                        <TableCell>
                        <Text>{idcard.cardId}</Text>
                        </TableCell>
                        <TableCell>
                            <Text>{idcard.allowEntry.string}</Text>
                            <this.accessColor value={idcard.allowEntry} studentId={idcard.studentId} itemRequest="entry"/>
                        </TableCell>
                        <TableCell>
                            <this.accessColor value={idcard.allowLunch} studentId={idcard.studentId} itemRequest="entry"/>
                        </TableCell>
                        <TableCell>
                        <EditItemModal item={idcard} getItems={(this.getItems)}></EditItemModal>
                        </TableCell>

                    </TableRow>
                ))}
                    </TableBody>
                </Table>
                </Flex>
            </View>
        );
    }
}
export default IdDashboard;
/*
            <Select label="Search Field" >
                <option value="lastName">Last Name</option>
                <option value="studentId">ID Number</option>
                <option value="cardId">Access Card ID</option>
            </Select>
            <Search label="search" placeholder="Search..."
                    ref={inputRef}

            />
 */