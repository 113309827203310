//import logo from './logo.svg';
import React, { Component }from 'react';
import { OneBarNav } from './components/includes/Navbar'
import { Footer } from "./components/includes/Footer";
import IdDashboard from "./Pages/idDashboard/IdDashboard";
import {IdScanner} from "./Pages/Kiosk/scanId";
import { Amplify } from 'aws-amplify';
import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import './css/App.css';
import {  
  Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import onScan from "onscan.js";

Amplify.configure(awsExports);
/*
            <div className="content-wrapper">
             <section className="content">
                               <Route  path="/studentadd" element={ <StudentAddForm />} />
                 // <Route exact path="/student/:id" element={ <StudentAddForm /> } />
                  <Route exact path="/students/list" element={ <StudentList /> } />
*/
onScan.attachTo(document, {
  suffixKeyCodes: [13], // enter-key expected at the end of a scan
  reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
});
class App extends Component {
  render() {
    return (

        <Authenticator hideSignUp={true}>
          {({ signOut, user }) => (
        <Router>
            <OneBarNav signOut={signOut} username={user.username}/>

                <Routes>
                  <Route  path="/" element={<IdScanner />} />
                  <Route path="/mgmt" element={ <IdDashboard /> } />
                </Routes>


            <Footer />
            <NotificationContainer />

          </Router>
          )}
        </ Authenticator>

    );
  }
}
/*
export default App;
class App extends Component {

  
  constructor(props){
      super(props);

      this.components = {
        SignIn: {
          Footer() {
            const { toResetPassword } = useAuthenticator();
            return (
              <View textAlign="center">
                <Button fontWeight="normal" onClick={toResetPassword} size="small">
                  Custom Reset Password
                </Button>
              </View>
            );
          },
        },
      };
     

  }

    render () {
      return (
        <Authenticator components={this.components} hideSignUp={true}>
          {({ signOut, user }) => (

            <div>
                <button onClick={() => this.sendSample()}>Send Sample Message</button>
                <X8
                 first={this.state.data.f_name}
                 last={this.state.data.l_name}
                 img={this.state.data.imageName}
                 signOut={signOut}
                 entry={this.state.data.allowentry}
                />
            </div>
            
   
          )}
        </Authenticator>
        
      );

    };
  }*/
export default App;