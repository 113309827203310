import React, { Component } from 'react';

export class Footer extends Component{
    render(){
        return (
            <footer className="main-footer">
                <div className="pull-right hidden-xs">
                    <b>Version</b> 0.0.1
                </div>
                <strong> <a href="./Footer#">Visitor Management System</a>.</strong>
                All rights reserved.
            </footer>
        );
    }

}