import { w3cwebsocket as W3CWebSocket } from "websocket";

const client = new W3CWebSocket('wss://0l148l5nae.execute-api.us-east-1.amazonaws.com/dev');

function sendCard(cardId){
   let body = {"card_id": cardId, "action": "cardscan", "db_name": "sample", "building":1}
   client.send(JSON.stringify(body))

}
export { sendCard };
export { client } ;