import React, {Component} from "react";


import {getCard} from "../../Services/idcardActions";
import {
    Button, Flex, Icon, Text, View
} from '@aws-amplify/ui-react';
import onScan from 'onscan.js';
import {  client} from '../../Services/api';

import {AccessCard} from "../../components/AccessCard/accessCard";
export class IdScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {firstName: 'First Name', listName: "Last Name", image:"images/48.png", allowEntry: true},
            dataReceived: false,
            imageStyle: {width: 400, height: 400, borderRadius: 400 / 2},
            serverConnection: false,
            modalOpen: false
        };
        client.onopen = () => {
            console.log('WebSocket Client Connected');
            this.setState({serverConnection: true});

        };



    }
    handleOpen = () => this.setState({modalOpen: true })
    handleClose = () => this.setState( {modalOpen: false })

    componentWillUnmount(){
        onScan.detachFrom(document);
        client.close();
        this.setState({serverConnection: true});

    }
    sendSample(){
        onScan.simulate(document, "2698405404")
    }
    componentDidMount() {
        const self = this;
        document.addEventListener('scan',function (e) {
            //sendCard(e.detail.scanCode);
            getCard(e.detail.scanCode).then((r) => {
                console.log(r);
                self.setState({
                    data: r,
                    dataReceived: true
                }, ()=>{
                    self.handleOpen();
                    console.log(self.state.data);
                });
            }).catch((error) =>{console.log("Error with getting response", error)});


        });
        client.onmessage = (message) => {
            const data = (JSON.parse(message.data));
            if (data.message !== "Internal server error") {
                this.setState({
                    data: data,
                    dataReceived: true
                });


            }
        };
    }

    render() {
        let connection;
        if (this.state.serverConnection) {
         connection =  <div className="circle-connected"/>;

        } else {
            connection =  <div className="circle-disconnected"/>;
        }
        return (

            <View
                className="kiosk"
                width={"100%"}
                height={"100%"}
                position={"absolute"}
            >
                <Flex
                    direction="row"
                    justifyContent="right"
                    alignItems="right"
                >
                    {connection}

                </Flex>

                <Flex
                    direction="row"
                    justifyContent="top"
                    alignItems="center"
                >
                    <Icon ariaLabel="HiOutlineIdentification"></Icon>
                    <Button onClick={this.sendSample}> Simulate </Button>
                </Flex>

                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"

                >
                    <AccessCard data={this.state.data} modalOpen={this.state.modalOpen} handleOpen={this.handleOpen} handleClose={this.handleClose} />
                    <Text color={"white"} fontWeight={400} fontSize={50}> Please Scan your ID </Text>

                </Flex>

            </View>

        );
    }
}