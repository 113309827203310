import React, { Component } from 'react';
import {Modal, Form} from 'react-bootstrap';
import * as mutations from '../../graphql/mutations';
import { API } from 'aws-amplify';
import {
    Text,
    Button,
    CheckboxField,
    TextField
} from "@aws-amplify/ui-react";
import onScan from '../../../node_modules/onscan.js/onscan';
import ImgHere from "../ImgHere";



class EditItemModal extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        console.log("item", this.props.item)
        this.state = {
            id: this.props.item.id,
            modalOpen: false,
            allowEntry: this.props.item.allowEntry,
            allowLunch: this.props.item.allowLunch,
            firstName: this.props.item.firstName,
            lastName: this.props.item.lastName,
            cardId: this.props.item.cardId,
            imageName: "images/"+this.props.item.studentId+".png",
            _version: this.props.item._version,
            imageStyle: {width: 200, height: 200, borderRadius: 200/ 2}
        };



    }
    componentDidMount() {
        const self = this;
        document.addEventListener('scan',function (e)
        {
            self.setState({cardId: e.detail.scanCode});
            //console.log(e.sCode);
        });
    }

    handleChange = (e) => {
        this.setState({[e.name]: e.value });
    }
    handleCheck = (e) => {
        this.setState({[e.target.name]: e.target.checked});

    };
    simulate(){
        onScan.simulate(document, "2698405404")
    }
    async handleSubmit(event) {
        let editItem = {
                "id": this.state.id,
                "allowEntry": this.state.allowEntry,
                "allowLunch": this.state.allowLunch,
                "cardId": this.state.cardId,
                "_version": this.state._version
        }
        console.log("Item State", this.state.item)
        const response = await API.graphql({ query: mutations.updateSTUDENTS, variables: {input: editItem}});
        console.log(response);
        this.props.getItems();
        this.handleClose();
        event.preventDefault();
    }
    handleOpen = () => this.setState({modalOpen: true })
    handleClose = () => this.setState( {modalOpen: false })


    render () {
        const inlineStyle = {
            modal : {
                marginTop: '0px !important',
                marginLeft: 'auto',
                marginRight: 'auto',
                alignCenter: true
            }
        };

        return (
            <>
                <Button  onClick={this.handleOpen}> Edit </Button>
                    <Modal  show={this.state.modalOpen} onHide={this.handleClose} centered>
                        <Modal.Header closeButton      >
                            <Modal.Title style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                Edit Access
                            </Modal.Title>
                        </Modal.Header>
                        <ImgHere  img={this.state.imageName} imageStyle={this.state.imageStyle} imageClass={"image-container"}/>
                        <Modal.Body>
                         <Text>{this.state.firstName} {this.state.lastName}</Text>
                            <Form>
                                <Form.Group>
                                    <CheckboxField  name='allowEntry' label='Building Entry' checked={this.state.allowEntry}  onChange={this.handleCheck}  />
                                    <CheckboxField   name='allowLunch' label='Off Campus Lunch' checked={this.state.allowLunch} onChange={this.handleCheck} />
                                    <TextField  name='cardId' label='Access Card Id'   onChange={this.handleChange}  value={this.state.cardId} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleSubmit}>Save</Button>
                            <Button onClick={this.simulate}>Simulate</Button>
                        </Modal.Footer>
                    </Modal>
            </>

        );
    }
}
export default EditItemModal;