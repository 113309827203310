/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBUILDINGS = /* GraphQL */ `
  query GetBUILDINGS($id: ID!) {
    getBUILDINGS(id: $id) {
      id
      building
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bUILDINGSDISTRICTSId
    }
  }
`;
export const listBUILDINGS = /* GraphQL */ `
  query ListBUILDINGS(
    $filter: ModelBUILDINGSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBUILDINGS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        building
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bUILDINGSDISTRICTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBUILDINGS = /* GraphQL */ `
  query SyncBUILDINGS(
    $filter: ModelBUILDINGSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBUILDINGS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        building
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bUILDINGSDISTRICTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTRACKS = /* GraphQL */ `
  query GetTRACKS($id: ID!) {
    getTRACKS(id: $id) {
      id
      cardId
      time
      date
      inbuilding
      STUDENTS {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tRACKSSTUDENTSId
    }
  }
`;
export const listTRACKS = /* GraphQL */ `
  query ListTRACKS(
    $filter: ModelTRACKSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTRACKS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cardId
        time
        date
        inbuilding
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tRACKSSTUDENTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTRACKS = /* GraphQL */ `
  query SyncTRACKS(
    $filter: ModelTRACKSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTRACKS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cardId
        time
        date
        inbuilding
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tRACKSSTUDENTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const getSTUDENTS = /* GraphQL */ `
  query GetSTUDENTS($id: ID!) {
    getSTUDENTS(id: $id) {
      id
      studentId
      firstName
      lastName
      cardId
      familyId
      adUsername
      image
      allowLunch
      accessGroup
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      allowEntry
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sTUDENTSDISTRICTSId
    }
  }
`;
export const listSTUDENTS = /* GraphQL */ `
  query ListSTUDENTS(
    $filter: ModelSTUDENTSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSTUDENTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSTUDENTS = /* GraphQL */ `
  query SyncSTUDENTS(
    $filter: ModelSTUDENTSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSTUDENTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      nextToken
      startedAt
    }
  }
`;
export const getDISTRICTS = /* GraphQL */ `
  query GetDISTRICTS($id: ID!) {
    getDISTRICTS(id: $id) {
      id
      destrict
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDISTRICTS = /* GraphQL */ `
  query ListDISTRICTS(
    $filter: ModelDISTRICTSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDISTRICTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDISTRICTS = /* GraphQL */ `
  query SyncDISTRICTS(
    $filter: ModelDISTRICTSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDISTRICTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
