/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBUILDINGS = /* GraphQL */ `
  mutation CreateBUILDINGS(
    $input: CreateBUILDINGSInput!
    $condition: ModelBUILDINGSConditionInput
  ) {
    createBUILDINGS(input: $input, condition: $condition) {
      id
      building
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bUILDINGSDISTRICTSId
    }
  }
`;
export const updateBUILDINGS = /* GraphQL */ `
  mutation UpdateBUILDINGS(
    $input: UpdateBUILDINGSInput!
    $condition: ModelBUILDINGSConditionInput
  ) {
    updateBUILDINGS(input: $input, condition: $condition) {
      id
      building
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bUILDINGSDISTRICTSId
    }
  }
`;
export const deleteBUILDINGS = /* GraphQL */ `
  mutation DeleteBUILDINGS(
    $input: DeleteBUILDINGSInput!
    $condition: ModelBUILDINGSConditionInput
  ) {
    deleteBUILDINGS(input: $input, condition: $condition) {
      id
      building
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bUILDINGSDISTRICTSId
    }
  }
`;
export const createTRACKS = /* GraphQL */ `
  mutation CreateTRACKS(
    $input: CreateTRACKSInput!
    $condition: ModelTRACKSConditionInput
  ) {
    createTRACKS(input: $input, condition: $condition) {
      id
      cardId
      time
      date
      inbuilding
      STUDENTS {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tRACKSSTUDENTSId
    }
  }
`;
export const updateTRACKS = /* GraphQL */ `
  mutation UpdateTRACKS(
    $input: UpdateTRACKSInput!
    $condition: ModelTRACKSConditionInput
  ) {
    updateTRACKS(input: $input, condition: $condition) {
      id
      cardId
      time
      date
      inbuilding
      STUDENTS {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tRACKSSTUDENTSId
    }
  }
`;
export const deleteTRACKS = /* GraphQL */ `
  mutation DeleteTRACKS(
    $input: DeleteTRACKSInput!
    $condition: ModelTRACKSConditionInput
  ) {
    deleteTRACKS(input: $input, condition: $condition) {
      id
      cardId
      time
      date
      inbuilding
      STUDENTS {
        id
        studentId
        firstName
        lastName
        cardId
        familyId
        adUsername
        image
        allowLunch
        accessGroup
        allowEntry
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sTUDENTSDISTRICTSId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tRACKSSTUDENTSId
    }
  }
`;
export const createSTUDENTS = /* GraphQL */ `
  mutation CreateSTUDENTS(
    $input: CreateSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    createSTUDENTS(input: $input, condition: $condition) {
      id
      studentId
      firstName
      lastName
      cardId
      familyId
      adUsername
      image
      allowLunch
      accessGroup
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      allowEntry
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sTUDENTSDISTRICTSId
    }
  }
`;
export const updateSTUDENTS = /* GraphQL */ `
  mutation UpdateSTUDENTS(
    $input: UpdateSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    updateSTUDENTS(input: $input, condition: $condition) {
      id
      studentId
      firstName
      lastName
      cardId
      familyId
      adUsername
      image
      allowLunch
      accessGroup
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      allowEntry
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sTUDENTSDISTRICTSId
    }
  }
`;
export const deleteSTUDENTS = /* GraphQL */ `
  mutation DeleteSTUDENTS(
    $input: DeleteSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    deleteSTUDENTS(input: $input, condition: $condition) {
      id
      studentId
      firstName
      lastName
      cardId
      familyId
      adUsername
      image
      allowLunch
      accessGroup
      DISTRICTS {
        id
        destrict
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      allowEntry
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sTUDENTSDISTRICTSId
    }
  }
`;
export const createDISTRICTS = /* GraphQL */ `
  mutation CreateDISTRICTS(
    $input: CreateDISTRICTSInput!
    $condition: ModelDISTRICTSConditionInput
  ) {
    createDISTRICTS(input: $input, condition: $condition) {
      id
      destrict
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDISTRICTS = /* GraphQL */ `
  mutation UpdateDISTRICTS(
    $input: UpdateDISTRICTSInput!
    $condition: ModelDISTRICTSConditionInput
  ) {
    updateDISTRICTS(input: $input, condition: $condition) {
      id
      destrict
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDISTRICTS = /* GraphQL */ `
  mutation DeleteDISTRICTS(
    $input: DeleteDISTRICTSInput!
    $condition: ModelDISTRICTSConditionInput
  ) {
    deleteDISTRICTS(input: $input, condition: $condition) {
      id
      destrict
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
