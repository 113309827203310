import {API} from "aws-amplify";
import { listSTUDENTS} from "../graphql/queries";
import * as queries from "../graphql/queries";

export function fetchIdCards () {
    try {
        const apiData = API.graphql({query: listSTUDENTS, authMode: "AMAZON_COGNITO_USER_POOLS"});
        console.log('apiDate', apiData)
        const apiDataList = apiData.data.listSTUDENTS.items;
        console.log('id Card list', apiDataList);
       return  apiDataList;
    } catch (error) {
        console.log('error on fetching songs', error);
    }
};

export async function getCard(cardId) {
    console.log("Id Sent to getItem: ", cardId)
    let filter = {
        cardId: {
            eq: cardId // filter priority = 1
        }
    };
    try {
        let item;
         await API.graphql({query: queries.listSTUDENTS, variables: {filter: filter}}).then(response => {
            console.log(response.data.listSTUDENTS.items[0])
             if (response.data.listSTUDENTS.items.length === 0){
                 item = {firstName: "No Id", lastName: "Card Found", allowEnry: false, studentId: "None", cardId: cardId, allowLunch: false}
             } else if (response.data.listSTUDENTS.items.length === 1) {
                 item = response.data.listSTUDENTS.items[0];
             } else {
                 item = {firstName: "More then one users", lastName: "With the Same Id Card", allowEnry: false, studentId: "None", cardId: cardId, allowLunch: false}
             }

        });
        return  item;
    } catch (error){
        console.log("Error with getItem ", error);
    }
}