import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import {
    Text
} from "@aws-amplify/ui-react";

import ImgHere from "../ImgHere";

export class AccessCard extends Component {
    constructor(props) {
        super(props)

            this.state = {
                modalOpen: false,
                data: props.data,
                imageName: "1234",
                imageStyle: {width: 200, height: 200, borderRadius: 200/ 2}

            };

        this.handleOpen = this.props.handleOpen;
        this.handleClose = this.props.handleClose;
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data,
                                modalOpen: nextProps.modalOpen});
    }

    render () {
        let modelClass;
        if (this.state.data.allowEntry){
            modelClass = "modal-allow";
        } else {
            modelClass = "modal-deny";
        }
        return (
           <>
                <Modal show={this.state.modalOpen} onHide={this.handleClose} centered className={modelClass}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            Badge
                        </Modal.Title>
                    </Modal.Header>
                    <ImgHere img={"images/"+this.state.data.studentId+".png"} imageStyle={this.state.imageStyle} imageClass={"image-container"}/>
                    <Modal.Body>
                        <Text textAlign={"center"} fontSize={30}>{this.state.data.firstName} {this.state.data.lastName}</Text>
                    </Modal.Body>

                </Modal>
            </>
        );
    }

}