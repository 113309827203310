import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, {Component} from "react";
export class  OneBarNav extends Component {

    constructor(props) {
        super(props);
    };
    render()
    {
        return (
            <>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand className="top-nav">
                            <img
                                alt=""
                                src={require("./logo.png")}
                                width="60"
                                height="60"
                                className="d-inline-block align-top"
                            />{' '}
                            OneSchool
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link href="/">Attendance Scan</Nav.Link>
                            <Nav.Link href="/mgmt">ID Card Management</Nav.Link>
                            <Nav.Link href="#visitor">Visitor Check In</Nav.Link>
                            <Nav.Link href="#reports">Reports</Nav.Link>
                        </Nav>
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                Signed in as: <a href="#" onClick={ this.props.signOut }>{this.props.username}</a>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}