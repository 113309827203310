
import React, { useState } from "react";
import {  Storage } from "aws-amplify";
import "./ImgHere.css";


function ImgHere(props) {
  const { img, imgStyle, imageClass } = props;
const [image, setImage] = useState("../logo.svg");
Storage.configure({ track: true, level: "public" });
const getProfilePicture = async ({img}) => {
  Storage.get(img)
    .then(url => {
      console.log(url)
      var myRequest = new Request(url);
      fetch(myRequest).then(function(response) {
        if (response.status === 200) {
          setImage(url);
        }
      });
    })
    .catch(err => console.log(err));
};
  console.log({img})
  getProfilePicture({img});
  return (
  <div className={imageClass}>
     <img  src={image} 
     alt='123'
     style={imgStyle}
      />
  </div>
  );
}

export default ImgHere;
